import React, { Component, Fragment, useContext, useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import $ from 'jquery'; 
import {isTablet} from 'react-device-detect';
import {getWhite} from '../../Utils/Common';
import UserContext from '../UserContext';
import { ComponentTransition, AnimationTypes } from "react-component-transition";
import English from '../LanguageContent/English.json'
import Danish from '../LanguageContent/Danish.json'

export default function Navbar() {
    const [ isToggleOn, setIsToggleOn] =  useState(false)
    const {lang, setLang} = useContext(UserContext)
    const [slideBarContent, setSlideBarContent] = useState({})
    
    useEffect(() => {
        if (lang === "English") {
            setSlideBarContent({
                home: English.SLIDEBAR.home,
                product: English.SLIDEBAR.product,
                ourCompany: English.SLIDEBAR.ourCompany,
                findARetailer: English.SLIDEBAR.findARetailer,
                contactUs: English.SLIDEBAR.contactUs,
                becomeARetailer: English.SLIDEBAR.becomeARetailer
            })
        } else {
            setSlideBarContent({
                home: Danish.SLIDEBAR.home,
                product: Danish.SLIDEBAR.product,
                ourCompany: Danish.SLIDEBAR.ourCompany,
                findARetailer: Danish.SLIDEBAR.findARetailer,
                contactUs: Danish.SLIDEBAR.contactUs,
                becomeARetailer: Danish.SLIDEBAR.becomeARetailer
            })
        }
    }, [lang])

    return (
        <React.Fragment> 
            
            <div className='nav-content'>
                <div className='nav-bar'>
                    <ul className='option desktop'>
                        <li className='frn-span'> <img src="/assets/icons/pin-mini.png" ></img> FIND A RETAILER NEAR ME</li>
                        <li className='lang-section cursor-pointer'> 
                            <a onClick={ () => setLang("English")}>
                                <span style={lang === "English" ? {color: '#F05A14'} : null}>ENG</span>
                            </a> 
                            <span> | </span> 
                            <a onClick={() => setLang("Danish")}>
                                <span style={lang === "Danish" ? {color: '#F05A14'} : null}>DAN</span>
                            </a>
                        </li>
                    </ul>
                    <ul className='mobile header'>
                        <li className='f-left'><span className='txt-primary'>
                            <a onClick={ () => setLang("English")}>
                                <span style={lang === "English" ? {color: '#F05A14'} : null}>ENG</span>
                            </a> 
                            <span> | </span> 
                            <a onClick={() => setLang("Danish")}>
                                <span style={lang === "Danish" ? {color: '#F05A14'} : null}>DAN</span>
                            </a>
                        </span></li>
                        <li className='f-right'><span className='txt-white'>PHL <i className='noreg-carret-down f12'></i></span></li>
                    </ul>
                    <ul className='tablet header'>
                        <li className='f-left'><span className='txt-primary'>
                            <a onClick={ () => setLang("English")}>
                                <span style={lang === "English" ? {color: '#F05A14'} : null}>ENG</span>
                            </a> 
                            <span> | </span> 
                            <a onClick={() => setLang("Danish")}>
                                <span style={lang === "Danish" ? {color: '#F05A14'} : null}>DAN</span>
                            </a>
                        </span></li>
                    </ul>
                    
                </div>
                <div className='nav-footer'>
                    <ul className='option desktop'>
                        <li className='f-left'>
                            <a className='menu-toggle' className='img-nav cursor-pointer' onClick={() => setIsToggleOn(!isToggleOn)}><i className='noreg-nav menu-icon' id='norregate-menu'></i></a>
                        </li>
                        <li className='mid-li'> <a href='/'><img src='/assets/icons/norregate.png' id='norregate-img'></img></a></li>
                        <li className='f-right globe-li'>
                            <span>PHL <i className='noreg-carret-down f12'></i>  <img src='/assets/icons/earth.svg' id='norregate-earth' className='earth'></img></span>
                            <ul className='country'></ul>
                        </li>
                        
                    </ul>
                    <ul className='mobile option-mobile'>
                        <li className='f-left'><a href='/'><img src='/assets/icons/norregate.png' id='norregate-img-mobile'></img></a></li>
                        <li className='f-right'><a  className='img-nav cursor-pointer' onClick={() => setIsToggleOn(!isToggleOn)}><i className='noreg-nav-flpped menu-icon f28' id='norregate-flip-menu'></i></a></li>
                    </ul>
                </div>
                <ComponentTransition 
                enterAnimation={AnimationTypes.fade.enter}
                exitAnimation={AnimationTypes.fade.exit}
                >
                {isToggleOn ? 
                    <Fragment>
                        <div className='nav-side-bar' style={{zIndex: "999"}}>
                            <div className='close-side-bar'><a onClick={() => setIsToggleOn(false)} className='cursor-pointer'><i className='noreg-close f25' id='norregate-close'></i></a></div>
                            <ul className='menu'>
                                <li><a className='nav-link' href='/'>{slideBarContent.home}</a></li>
                                <li><a className='nav-link' href='/products'>{slideBarContent.product}</a></li>
                                <li><a className='nav-link' href='/our-company'>{slideBarContent.ourCompany}</a></li>
                                <li><a className='nav-link' href='/find-a-retailer'>{slideBarContent.findARetailer}</a></li>
                            </ul>
                            <ul className='menu-footer'>
                                <li><a className='nav-link' href='/find-a-retailer'>{slideBarContent.becomeARetailer}</a></li>
                                <li><a className='nav-link' href='/contact-us'>{slideBarContent.contactUs}</a></li>
                                
                            </ul>
                        </div>
                    </Fragment> : null
                }
                </ComponentTransition>
                
            </div>
                        
        </React.Fragment>  

    )
}
