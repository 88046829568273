import React, { Fragment, useEffect, useState, useContext } from "react";
import engData from './List.json'
import danData from './Dan.json'
import { useParams } from 'react-router'
import zIndex from "@material-ui/core/styles/zIndex";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Propelling from "../Propelling"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import UserContext from '../UserContext'

SwiperCore.use([Navigation]);
SwiperCore.use([Scrollbar]);


function ProductView({ match }) {
    const { lang } = useContext(UserContext)
    const { sku } = useParams()
    const [products, setProducts] = useState('')
    const [info, setInfo] = useState([]);
    const [selectedSlide, setSelectedSlide] = useState(0);
    // let selectedSlide = 0;
    useEffect(() => {
        if (lang === "English") {
            setProducts(engData.products)
        } else {
            setProducts(danData.products)
        }

    }, [lang])

    const updateCurrentSlide = (index) => {
        setSelectedSlide(index);
        console.log("selectedSlide", selectedSlide);
    };


    useEffect(() => {

        for (let x of products) {
            if (x.sku === sku) {

                const array = () => {
                    return (
                        <Fragment>
                            <div className="row product-view">
                                <div className="col-lg-5">
                                    <div className="row desktop">
                                        <div className="col-md-12">
                                            <ul className="bread-crumbs">
                                                {
                                                    x.breadCrumbs.map(bc => {
                                                        return (
                                                            <>
                                                                <li>{bc}</li>
                                                                <li>/</li>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-md-12">
                                            <h4>{x.name}</h4>
                                            <h2>{x.header}</h2>
                                        </div>


                                        {
                                            x.colors.length > 0 ?
                                                <div className="col-md-12 mb-4vh">
                                                    <ul className="color-listing">
                                                        {

                                                            x.colors.map(color => {

                                                                return (
                                                                    color.display ?
                                                                        <li>
                                                                            <span className="prod-circle-color" style={{ background: color.color }}></span><br />
                                                                            <span>{color.label}</span>
                                                                        </li>
                                                                        :
                                                                        <></>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            x.attr.length > 0 ?
                                                <div className="col-md-12 mb-4vh">
                                                    <ul className="color-listing">
                                                        {
                                                            x.attr.map((attr,index) => {
                                                                return (
                                                                    <li>
                                                                        {/* <span className={(x.circle ? "prod-circle-color" : "p-lr-1m") + " attributes "}>{attr}</span> */}
                                                                        <a href={`/product/sku/${x.types[index]}`}><span className={(x.circle ? "prod-circle-color" : "p-lr-1m") + " attributes "}>{attr}</span></a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    {
                                                        x.sizes ?
                                                            <>
                                                                <label className="txt-primary" onClick={ () => setSelectedSlide(5)}>CHECK SIZE GUIDE TEST <img className="img-guide" src="/assets/icons/arrow.png" /></label>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                        }

                                        <div className="col-md-12">
                                            {
                                                x.store.map(store => {
                                                    return (
                                                        <div className="form-group">
                                                            {/* <a href={store.url} className="btn btn-primary-line">{store.label}</a> */} 
                                                            <a href='https://shp.ee/haqnkf2' className="btn btn-primary-line shopee-btn" id="shopee-btn"></a>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="form-group">
                                                <a href='https://s.lazada.com.ph/s.TyJ0i' className="btn btn-primary-line lazada-btn" id="lazada-btn"></a>
                                            </div>
                                            <div className="form-group">
                                                <a className="btn btn-primary">CHECK RETAILERS</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <Carousel infiniteLoop={true} selectedItem={selectedSlide} onChange={updateCurrentSlide}  showThumbs={false}  >

                                        {
                                            x.images.map(img => {
                                                return (
                                                    <>
                                                        <img src={img} />
                                                    </>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>
                                {/* mobile start */}
                                <div className="col-md-12 mobile-header mobile text-center">
                                    <h4>{x.name}</h4>
                                    <h1>{x.header}</h1>
                                </div>


                                {
                                    x.colors.length > 0 ?
                                        <div className="col-md-12 mb-4vh mobile">
                                            <ul className="color-listing">
                                                {

                                                    x.colors.map(color => {

                                                        return (
                                                            color.display ?
                                                                <li>
                                                                    <span className="prod-circle-color" style={{ background: color.color }}></span><br />
                                                                    <span>{color.label}</span>
                                                                </li>
                                                                :
                                                                <></>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    x.attr.length > 0 ?
                                        <div className="col-md-12 mb-4vh mobile">
                                            <ul className="color-listing">
                                                {
                                                    x.attr.map(attr => {
                                                        return (
                                                            <li>

                                                                <span className={(x.circle ? "prod-circle-color" : "p-lr-1m") + " attributes "}>{attr}</span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {
                                                x.sizes ?
                                                    <>
                                                        {/* <label className="txt-primary mobile-guide">CHECK SIZE GUIDE <img className="img-guide" src="/assets/icons/arrow.png" /></label> */}
                                                        <label className="txt-primary mobile-guide" onClick={ () => setSelectedSlide(5)}>CHECK SIZE GUIDE <img className="img-guide" src="/assets/icons/arrow.png" /></label>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        :
                                        <></>
                                }

                                <div className="col-md-12 mobile text-center">
                                    {
                                        x.store.map(store => {
                                            return (
                                                <div className="form-group">
                                                    {/* <a href={store.url} className="btn btn-primary-line">{store.label}</a> */}
                                                    <a href='https://shp.ee/haqnkf2' className="btn btn-primary-line shopee-btn" id="shopee-btn"></a>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group">
                                        <a href='https://s.lazada.com.ph/s.TyJ0i' className="btn btn-primary-line lazada-btn" id="lazada-btn"></a>
                                    </div>
                                    <div className="form-group">
                                        <a className="btn btn-primary">CHECK RETAILERS</a>
                                    </div>
                                </div>
                                {/* mobile end */}

                                {/* tablet start */}
                                <div className=" col-lg-12 tablet mt-2m">
                                    <div className="row">
                                        <div className="col-md-7 mobile-header">
                                            <h4>{x.name}</h4>
                                            <h2>{x.header}</h2>
                                            {
                                                x.colors.length > 0 ?
                                                    <div className=" mb-4vh tablet">
                                                        <ul className="color-listing">
                                                            {

                                                                x.colors.map(color => {

                                                                    return (
                                                                        color.display ?
                                                                            <li>
                                                                                <span className="prod-circle-color" style={{ background: color.color }}></span><br />
                                                                                <span>{color.label}</span>
                                                                            </li>
                                                                            :
                                                                            <></>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                x.attr.length > 0 ?
                                                    <div className=" mb-4vh tablet">
                                                        <ul className="color-listing">
                                                            {
                                                                x.attr.map(attr => {
                                                                    return (
                                                                        <li>

                                                                            <span className={(x.circle ? "prod-circle-color" : "p-lr-1m") + " attributes "}>{attr}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        {
                                                            x.sizes ?
                                                                <>
                                                                    {/* <label className="txt-primary guide-label">CHECK SIZE GUIDE <img className="img-guide" src="/assets/icons/arrow.png" /></label> */}
                                                                    <label className="txt-primary guide-label" onClick={ () => setSelectedSlide(5)}>CHECK SIZE GUIDE <img className="img-guide" src="/assets/icons/arrow.png" /></label>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="col-md-5 tablet text-right">
                                            {
                                                x.store.map(store => {
                                                    return (
                                                        <div className="form-group">
                                                            {/* <a href={store.url} className="btn btn-primary-line">{store.label}</a> */}
                                                            <a href='https://shp.ee/haqnkf2' className="btn btn-primary-line shopee-btn" id="shopee-btn"></a>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="form-group">
                                            <a href='https://s.lazada.com.ph/s.TyJ0i' className="btn btn-primary-line lazada-btn" id="lazada-btn"></a>
                                            </div>
                                            <div className="form-group">
                                                <a className="btn btn-primary">CHECK RETAILERS</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* tablet end */}
                            </div>
                            <div className="row bg-dark prod-desc">
                                <div className="col-lg-6 mb-2m">
                                    <h3 className="txt-info">{x.productHeader}</h3>
                                    <p className="txt-white">{x.productDescription}</p>
                                </div>
                                <div className="col-lg-6 d-pl-2m">
                                    <span className="txt-white prod-mat">
                                        {lang === "English" ?
                                            <Fragment>PRODUCT MATERIALS</Fragment> :
                                            <Fragment>PRODUKT MATERIALER</Fragment>
                                        }
                                    </span>
                                    <ol className="pl-1em">
                                        {
                                            x.productMaterials.map(materials => {
                                                return (
                                                    <li className="txt-white">{materials}</li>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>
                            </div>
                            <div className="row collection-container">
                                <div className="col-md-12 mobile-padding">
                                    {lang === "English" ?
                                        <h3>DISCOVER MORE FROM THIS COLLECTION</h3> :
                                        <h3>OPDAG MERE FRA DENNE KOLLEKTION</h3>
                                    }
                                </div>
                                {
                                    x.collections.map(collections => {
                                        for (let col of products) {
                                            if (col.sku == collections) {
                                                return (
                                                    <div className="col-md-4 collection desktop">
                                                        <div className="img-collection">
                                                            <img src={col.main_image} />
                                                        </div>
                                                        <div className="collection-details">
                                                            <div className="details">
                                                                <h4>{col.header}</h4>
                                                                <p>{col.name}</p>
                                                            </div>
                                                            <span className="prod-circle-color" style={{ background: col.colors[0].color }}></span>
                                                        </div>
                                                    </div>
                                                )

                                            }
                                        }
                                    })
                                }
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    navigation={true}
                                    scrollbar={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        481: {
                                            slidesPerView: 2,
                                        },
                                        801: {
                                            slidesPerView: 3,
                                        },
                                    }}
                                    className="productView-Swiper products-slider tablet-mobile"
                                >
                                    {
                                        x.collections.map(collections => {
                                            for (let col of products) {
                                                if (col.sku == collections) {
                                                    return (
                                                        <SwiperSlide className="item">
                                                            <div className="img-collection">
                                                                <img src={col.main_image} />
                                                            </div>
                                                            <div className="collection-details">
                                                                <div className="details">
                                                                    <h4 className="color-black">{col.header}</h4>
                                                                    <p>{col.name}</p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>

                                                    )

                                                }
                                            }
                                        })
                                    }
                                </Swiper>
                            </div>
                            <Propelling />
                        </Fragment>
                    )
                }
                setInfo(array)
            }
        }
    }, [sku, products,selectedSlide])


    return (
        <div className="bg-gray">
            {info}
        </div>
    )
}
export default ProductView; 