import React, { Fragment, useState} from "react";

function Test() {
    return (
        <div class="bg-black">
            <button className="btn btn-primary">Primary Button</button><br/><br/>
            <button className="btn btn-info">Info Button</button><br/><br/>
            <button className="btn btn-white">Info Button</button><br/><br/>
            <button className="btn btn-black">Black Button</button><br/><br/>
            <button className="btn btn-white-reverse">Reverse Button</button><br/><br/>
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <h6>Header 6</h6>
            <p>Paragraph</p>
        </div>
    )
}

export default Test; 