import React, { Fragment, useEffect, useState, useContext } from 'react'
import Propelling from "./Propelling"
import UserContext from "./UserContext";
import Danish from './LanguageContent/Danish.json'
import English from './LanguageContent/English.json'

export default function OurCompany() {

    const {lang} = useContext(UserContext)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [ourCompanyContent, setOurCompanyContent] = useState([])
    const [buttonContent, setButtonContent] = useState("")
    
    useEffect(() => {
        document
          .querySelectorAll(
            "#norregate-img,#norregate-menu,#norregate-earth,#norregate-close,#norregate-img-mobile,#norregate-menu-mobile,#norregate-earth-mobile,#norregate-close-mobile"
          )
          .forEach((el) => {
            el.classList.add("invert");
          });

        document.querySelectorAll(
            "#norregate-flip-menu"
        )
        .forEach((el) => {
            el.classList.add("txt-white");
        });
      }, []);

    useEffect(() => {

        const screenResize = () => {
            const newWidth = window.innerWidth;
          setScreenWidth(newWidth);
        }
    
        window.addEventListener("resize", screenResize);
    
        return () => window.removeEventListener("resize", screenResize) 
    
      }, []);

    useEffect(() => {
        if (lang === "English") {
            setOurCompanyContent({
                landingPageHead: English.OURCOMPANY.landingPageHead,
                landingPageTitle1: English.OURCOMPANY.landingPageTitle1,
                landingPageTitle2: English.OURCOMPANY.landingPageTitle2,
                landingPageBody: English.OURCOMPANY.landingPageBody,
                inspirationHead: English.OURCOMPANY.inspirationHead,
                inspirationTitle1: English.OURCOMPANY.inspirationTitle1,
                inspirationTitle2: English.OURCOMPANY.inspirationTitle2,
                inspirationBody: English.OURCOMPANY.inspirationBody,
                dnaHead: English.OURCOMPANY.dnaHead,
                dnaTitle1: English.OURCOMPANY.dnaTitle1,
                dnaTitle2: English.OURCOMPANY.dnaTitle2,
                dnabody: English.OURCOMPANY.dnabody,
                futureHead: English.OURCOMPANY.futureHead,
                futureTitle1: English.OURCOMPANY.futureTitle1,
                futureTitle2: English.OURCOMPANY.futureTitle2,
                futureBody: English.OURCOMPANY.futureBody,
                callToActionHead: English.HOME.callToActionHead,
                newsLetterHead: English.HOME.newsLetterHead,
                newsLetterBody: English.HOME.newsLetterBody
            })
            setButtonContent({
                viewProducts: English.BUTTON.viewProducts,
                becomeARetailer: English.BUTTON.becomeARetailer,
                signUp: English.BUTTON.signUp,
                ourCompany: English.BUTTON.ourCompany,
                giveUsAFollow: English.BUTTON.giveUsAFollow
            })
        } else {
            setOurCompanyContent({
                landingPageHead: Danish.OURCOMPANY.landingPageHead,
                landingPageTitle1: Danish.OURCOMPANY.landingPageTitle1,
                landingPageTitle2: Danish.OURCOMPANY.landingPageTitle2,
                landingPageBody: Danish.OURCOMPANY.landingPageBody,
                inspirationHead: Danish.OURCOMPANY.inspirationHead,
                inspirationTitle1: Danish.OURCOMPANY.inspirationTitle1,
                inspirationTitle2: Danish.OURCOMPANY.inspirationTitle2,
                inspirationBody: Danish.OURCOMPANY.inspirationBody,
                callToActionHead: Danish.HOME.callToActionHead,
                dnaHead: Danish.OURCOMPANY.dnaHead,
                dnaTitle1: Danish.OURCOMPANY.dnaTitle1,
                dnaTitle2: Danish.OURCOMPANY.dnaTitle2,
                dnabody: Danish.OURCOMPANY.dnabody,
                futureHead: Danish.OURCOMPANY.futureHead,
                futureTitle1: Danish.OURCOMPANY.futureTitle1,
                futureTitle2: Danish.OURCOMPANY.futureTitle2,
                futureBody: Danish.OURCOMPANY.futureBody,
                newsLetterHead: Danish.HOME.newsLetterHead,
                newsLetterBody: Danish.HOME.newsLetterBody
            })
            setButtonContent({
                viewProducts: Danish.BUTTON.viewProducts,
                becomeARetailer: Danish.BUTTON.becomeARetailer,
                signUp: Danish.BUTTON.signUp,
                ourCompany: Danish.BUTTON.ourCompany,
                giveUsAFollow: Danish.BUTTON.giveUsAFollow
            })
        }
    }, [lang])
      
    return (
        <div className='oc_container'>
            <div className='row'>
                <div className='col-12'>

                </div>
            </div>
            <div className='oc-rectangle-26'>
                <img 
                    src={(screenWidth <= 480) ? '/assets/images/mobile/MB_Company_Landing.png' 
                        : (screenWidth <= 1024 ) ? '/assets/images/tablet/TB_Company_Landing.png' 
                        : (screenWidth > 1024) ? '/assets/images/desktop/DT_Company_Landing.png' 
                        : null}
                    />
                <div>
                    <h6>{ourCompanyContent.landingPageHead}</h6>
                    <h1>{ourCompanyContent.landingPageTitle1}</h1>
                    <h1>{ourCompanyContent.landingPageTitle2}</h1>
                </div>
            </div>
            <div className='oc-rectangle-27'>
                <img  
                src={(screenWidth <= 480) ? '/assets/images/mobile/MB_Company_Inspiration.png' 
                    : (screenWidth <= 1024 ) ? '/assets/images/tablet/TB_Company_Inspiration.png'  
                    : (screenWidth > 1024) ? '/assets/images/desktop/DT_Company_Inspiration.png'
                    : null}/> 
                <div>
                    <h6>{ourCompanyContent.inspirationHead}</h6>
                    <h2 className='text-271'>{ourCompanyContent.inspirationTitle1}</h2>
                    <h2 className='text-272'>{ourCompanyContent.inspirationTitle2}</h2>
                    <p>
                        {ourCompanyContent.inspirationBody}
                    </p>
                </div>
            </div>
            <div className='oc-rectangle-28'>
                <img  
                src={(screenWidth <= 480) ? '/assets/images/mobile/MB_Company_DNA.png' 
                    : (screenWidth <= 1024 ) ? '/assets/images/tablet/TB_Company_DNA.png'  
                    : (screenWidth > 1024) ? '/assets/images/desktop/DT_Company_DNA.png'
                    : null}/> 
                <div >
                    <h6>{ourCompanyContent.dnaHead}</h6>
                    <h2>{ourCompanyContent.dnaTitle1}</h2>
                    <h2>{ourCompanyContent.dnaTitle2}</h2>
                    <p>
                        {ourCompanyContent.dnabody}
                    </p>
                    <button className='btn btn-primary'>
                    {buttonContent.viewProducts}
                    </button>
                </div>
            </div>
            <div className='oc-rectangle-29'>
                <img  
                src={(screenWidth <= 480) ? '/assets/images/mobile/MB_Company_Future.png' 
                    : (screenWidth <= 1024 ) ? '/assets/images/tablet/TB_Company_Future.png'  
                    : (screenWidth > 1024) ? '/assets/images/desktop/DT_Company_Future.png'
                    : null}/> 
                <div >
                    <h6>{ourCompanyContent.futureHead}</h6>
                    <h2 className='text-291'>{ourCompanyContent.futureTitle1}</h2>
                    <h2 className='text-292'>{ourCompanyContent.futureTitle2}</h2>
                    <p>
                       {ourCompanyContent.futureBody}
                    </p>
                    <button className='btn btn-white-reverse'>
                    {buttonContent.giveUsAFollow}
                    </button>
                </div>
            </div>
            <Propelling/>
            {/* <div className='oc-rectangle-30'>
                <img 
                    src={(screenWidth <= 480) ? '/assets/images/mobile/MB_CTA.png' 
                    : (screenWidth <= 1024 ) ? '/assets/images/tablet/TB_Home_CTA.png'  
                    : (screenWidth > 1024) ? '/assets/images/desktop/DT_Home_CTA.png'
                    : null}
                />
                <div>
                    <h1>
                        {ourCompanyContent.callToActionHead}
                    </h1>
                    <button className='btn btn-primary mt-2'>
                    {buttonContent.becomeARetailer}
                    </button>
                </div>
            </div>
            <div className='oc-rectangle-31'>
                <div className='oc-div-31'>
                    <h2>{ourCompanyContent.newsLetterHead}</h2>
                    <p>{ourCompanyContent.newsLetterBody}</p>
                </div>
                <div className='oc-div-32'>
                    <input placeholder="Email Address"></input>
                    <div>
                        <button className="btn btn-black button">{buttonContent.signUp}</button>
                    </div>
                </div>
            </div> */}
        </div>    
    )
}