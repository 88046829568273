import React, { Fragment, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import validator from 'validator'

function ContactUs() {
  const [name, setName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    var footer = document.querySelector(".footer");
    footer.classList.add("hide");
  });

  useEffect(() => {
    document
      .querySelectorAll(
        "#norregate-img,#norregate-menu,#norregate-earth,#norregate-close,#norregate-img-mobile,#norregate-menu-mobile,#norregate-earth-mobile,#norregate-close-mobile"
      )
      .forEach((el) => {
        el.classList.add("invert");
      });
    document.querySelectorAll(
      "#norregate-flip-menu"
    )
      .forEach((el) => {
        el.classList.add("txt-white");
      });
  }, []);

  const sendMessage = async () => {
    if (!validator.isEmail(emailAddress)) {
      return Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please input a valid email",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'swal-button'
        }
      })
    }
    const request = await fetch('https://n047dfodhg.execute-api.ap-southeast-1.amazonaws.com/contactus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        emailAddress,
        contactNumber,
        message
      })
    })
    if (request.status === 200) {
      setName('')
      setContactNumber('')
      setEmailAddress('')
      setMessage('')
      return Swal.fire({
        title: "Success",
        icon: "success",
        text: "You have successfully sent your message.",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'swal-button'
        }
      })
    }
    if (request.status !== 200) {
      return Swal.fire({
        title: "Error",
        icon: "error",
        text: "Unsuccessfull operation. Please refresh this page and try again.",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'swal-button'
        }
      })
    }

  }

  return (
    <>
      <div className="contact-us background" />
      <div className="contact-us">
        <div className="content two-columns-grid">
          <div className="title">
            <h1>CONTACT US</h1>
          </div>
          <div className="description">
            <p>
              Got a question? Interested in our brand? Looking to join our
              community of distributors? Pop us a message below.
            </p>
          </div>
          <div className="item">
            <input placeholder="Name" onChange={e => setName(e.target.value)} value={name} />
          </div>
          <div className="item">
            <input placeholder="Email Address" onChange={e => setEmailAddress(e.target.value)} value={emailAddress} />
          </div>
          <div className="item">
            <input placeholder="Contact Number" onChange={e => setContactNumber(e.target.value)} value={contactNumber} />
          </div>
          <div className="item">
            <label htmlFor="attachment" className="attachment">
              Add Attachments
            </label>
            <input
              type="file"
              id="attachment"
              name="attachment"
              style={{ display: "none" }}
            />
          </div>
          <div className="textarea">
            <textarea placeholder="Your message" onChange={e => setMessage(e.target.value)} value={message}/>
          </div>
          <div className="button">
            <button className="btn btn-primary" onClick={() => sendMessage()}>SEND</button>
          </div>
        </div>

        {/* MOBILE VIEW */}

        <div className="content contact-us-mobile">
          <div className="title">
            <h1>CONTACT US</h1>
          </div>
          <div className="description">
            <p>
              Got a question? Interested in our brand? Looking to join our
              community of distributors? Pop us a message below.
            </p>
          </div>
          <div className="item">
            <input placeholder="Name" />
          </div>
          <div className="item">
            <input placeholder="Email Address" />
          </div>
          <div className="item">
            <input placeholder="Contact Number" />
          </div>
          <div className="textarea">
            <textarea placeholder="Your message" />
          </div>
          <div className="button">
            <button className="btn btn-primary">SEND</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
