import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Banner({bannerProps}){
	const {title, content, destination, label} = bannerProps
	return(
        <Container fluid>
            <Row>
                <Col style={{minHeight: "545px", backgroundColor: '#e9ecef', marginTop: "-100px"}}>
                    <div style={{marginLeft: '100px'}}>
                        <h1 style={{marginTop: "150px"}}>{title}</h1>
                        <p>{content}</p>
                        <Link className="btn btn-primary" to={destination}>{label}</Link>
                    </div>
                </Col>
            </Row>
        </Container>
	)
}