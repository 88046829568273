import React, { Fragment, useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import UserContext from "./UserContext";
import Danish from './LanguageContent/Danish.json'
import English from './LanguageContent/English.json'
import Propelling from './Propelling'

SwiperCore.use([Navigation]);
SwiperCore.use([Scrollbar]);

function Home() {

  const {lang} = useContext(UserContext)
  const [homeContent, setHomeContent] = useState([])
  const [buttonContent, setButtonContent] = useState("")

  useEffect(() => {
    document
      .querySelectorAll(
        "#norregate-img,#norregate-menu,#norregate-earth,#norregate-close,#norregate-img-mobile,#norregate-menu-mobile,#norregate-earth-mobile,#norregate-close-mobile"
      )
      .forEach((el) => {
        el.classList.add("invert");
      });

      document
      .querySelectorAll(
        "#norregate-flip-menu"
      )
      .forEach((el) => {
        el.classList.add("txt-white");
      });
      
  }, []);

  useEffect(() => {
    if (lang === "English") {
      setHomeContent({
        landingPage: English.HOME.landingPage,
        introHead: English.HOME.introHead,
        introBody: English.HOME.introBody,
        collectionsHead: English.HOME.collectionsHead,
        colletionBody: English.HOME.colletionBody,
        callToActionHead: English.HOME.callToActionHead,
        newsLetterHead: English.HOME.newsLetterHead,
        newsLetterBody: English.HOME.newsLetterBody
      })
      setButtonContent({
        viewProducts: English.BUTTON.viewProducts,
        becomeARetailer: English.BUTTON.becomeARetailer,
        signUp: English.BUTTON.signUp,
        ourCompany: English.BUTTON.ourCompany
      })
    } else {
      setHomeContent({
        landingPage: Danish.HOME.landingPage,
        introHead: Danish.HOME.introHead,
        introBody: Danish.HOME.introBody,
        collectionsHead: Danish.HOME.collectionsHead,
        colletionBody: Danish.HOME.colletionBody,
        callToActionHead: Danish.HOME.callToActionHead,
        newsLetterHead: Danish.HOME.newsLetterHead,
        newsLetterBody: Danish.HOME.newsLetterBody
      })
      setButtonContent({
        viewProducts: Danish.BUTTON.viewProducts,
        becomeARetailer: Danish.BUTTON.becomeARetailer,
        signUp: Danish.BUTTON.signUp,
        ourCompany: Danish.BUTTON.ourCompany
      })
    }
  }, [lang])
  return (
    <>
      <div className="landing-page top">
        <div className="content">
          <h1 className="title ">{homeContent.landingPage}</h1>
          <a href="/products" className="btn btn-primary button">{buttonContent.viewProducts}</a>
        </div>
      </div>
      <div className="our-company">
        <div className="content">
          <h2 className="title">
            {homeContent.introHead}
          </h2>
          <p className="description">
            {homeContent.introBody}
          </p>
          <a href="/our-company" className="btn btn-info button">{buttonContent.ourCompany}</a>
        </div>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        navigation={true}
        scrollbar={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          481: {
            slidesPerView: 2,
          },
          801: {
            slidesPerView: 3,
          },
        }}
        className="mySwiper products-slider"
      >
        <SwiperSlide className="item">
          <a href="/product/sku/forkant">
            <div className="name">
              <h5>THE FORKANT</h5>
              <h4>BLACK BIKE HELMET</h4>
            </div>
            <div className="img-swiper-container">
              <img className="img-m31em" id="forkant" src="\assets\products\Forkant_bk_tr_4.png" alt="Forkant_bk_tr_4"/>
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className="item">
          <a href="/product/sku/front-light-400">
            <div className="name">
              <h5>THE STJERNE</h5>
              <h4>400 FRONT LIGHT</h4>
            </div>
            <img className="img-m31em" src="\assets\products\Stjerne_tr_3.png" alt="Stjerne_tr_3" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="item">
          <a href="/product/sku/ledsager">
            <div className="name">
              <h5>THE LEDSAGER</h5>
              <h4>BIKE BAG</h4>
            </div>
            <div className="img-swiper-container">
              <img className="img-m31em" id="ledsager" src="\assets\products\Ledsager_tr_1.png" alt="Ledsager_tr_1" />
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className="item">
          <a href="/product/sku/skal">
            <div className="name">
              <h5>THE SKAL</h5>
              <h4>BICYCLE BAG</h4>
            </div>
            <img className="img-m31em" src="\assets\products\Skal_tr_1.png" alt="Skal_tr_1" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="item">
          <a href="/product/sku/overfore">
            <div className="name">
              <h5>THE OVERFORE</h5>
              <h4>BICYCLE RACK</h4>
            </div>
            <div className="img-swiper-container">
            <img className="img-m31em" id="overfore" src="\assets\products\Overfore_tr_3.png" alt="Overfore_tr_3" />
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className="item">
          <a href="/product/sku/pude">
            <div className="name">
              <h5>THE PUDE</h5>
              <h4>SADDLE COVER</h4>
            </div>
            <img className="img-m31em" id="pude" src="\assets\products\Pude_tr_2.png" alt="Pude_tr_2" />
          </a>
        </SwiperSlide>
      </Swiper>
      <div className="landing-page bottom">
        <div className="content">
          <h1 className="title m-f30 mob-mb-1em">{homeContent.collectionsHead}</h1>
          <p className="description mob-mb-2em">
            {homeContent.colletionBody}
          </p>
          <a href="/products" className="btn btn-white button">{buttonContent.viewProducts}</a>
        </div>
      </div>
      <Propelling/>
      {/* <div className="landing-page retailer">
        <div className="content">
          <h3 className="title">
            {homeContent.callToActionHead}
          </h3>
          <a href="/contact-us" className="btn btn-primary button">{buttonContent.becomeARetailer}</a>
        </div>
      </div>
      <div className="subscribe">
        <div className="left">
          <div className="content">
            <h3 className="title">{homeContent.newsLetterHead}</h3>
            <p className="description">
             {homeContent.newsLetterBody}
            </p>
          </div>
        </div>
        <div className="right">
          <div className="content">
            <div className="column-one">
              <input className="email" placeholder="Email Address" />
            </div>
            <div className="column-two">
              <button className="btn btn-black button">{buttonContent.signUp}</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Home;
