import React, { Component, Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import permission, {PermissionContext } from 'react-access-permission';
import { useHistory, useLocation } from 'react-router-dom';

// UTILITIES
import PublicRoute from './Utils/PublicRoute';
import history from './history';

// Redux
import { PersistGate } from 'redux-persist/integration/react';
import {  persistor, configureStore  }  from './store';
import { LastLocationProvider } from "react-router-last-location";

// Components
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import Home from "./components/Home"; 
import ContactUs from "./components/ContactUs";
import PageNotFound from "./components/layouts/PageNotFound";
import OurCompany from "./components/OurCompany";
import Retailer from "./components/Retailer";
import ScrollButton from "./components/layouts/ScrollToTop";

import Test from "./components/test";
import Products from "./components/Products/Products";
import ProductView from "./components/Products/ProductView";

//import Context
import { UserProvider } from "./components/UserContext";

export default function App() {

  const [lang, setLang] = useState("English")

  useEffect(() => {
    const language = localStorage.getItem('language')

    if(language) {
      setLang(language)
    } else {
      localStorage.setItem('language', lang)
    }
  }, [])

  useEffect(() => {
    return localStorage.setItem('language', lang)
  }, [lang])
  
  return (
    <UserProvider value={{lang, setLang}}>
      <Router history={history}>
        <LastLocationProvider>
          <PersistGate persistor={persistor}>
            <React.Fragment>
              <Navbar />
              <Switch>
                  <PublicRoute path="/" exact component={Home}/>
                  <PublicRoute path="/contact-us" exact component={ContactUs}/>
                  <PublicRoute path="/test" exact component={Test}/>
                  <PublicRoute path="/our-company" exact component={OurCompany}/>
                  <PublicRoute path="/products" exact component={Products}/>
                  <PublicRoute path="/product/sku/:sku" exact component={ProductView}/>
                  <PublicRoute path="/find-a-retailer" exact component={Retailer}/>
                  <PublicRoute component={PageNotFound} />
              </Switch>
              {/* <ScrollButton /> */}
              <Footer />
            </React.Fragment>
          </PersistGate>
        </LastLocationProvider>
      </Router>
    </UserProvider>
  );
}

