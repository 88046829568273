import React, { useContext, useEffect, useState } from 'react'
import UserContext from "../UserContext";

const Footer = () => {
    const { lang, setLang } = useContext(UserContext)
    const [data, setData] = useState([])

    useEffect(() => {
        if (lang === "English") {
            setData({
                ourProducts: "Our Products",
                clothing: "Clothing",
                protection: "Protection",
                rackBags: "React & Bags",
                lights: "Lights",
                bikeGear: "Bike Gear",
                toolkits: "Toolkits",
                aboutNorregade: "About Norregade",
                ourBrand: "Our Brand",
                becomeARetailer: "Become a Retailer",
                ourDistributor: "Our Distributor",
                privacyPolicy: "Privacy Policy",
                support: "Support",
                returnExchange: "Return and Exchange",
                feedback: "Feedback",
                productManuals: "Product Manuals",
                contactUs: "Contact Us",
                findARetailer: "Find a Retailer",
                followUs: "Follow Us",
                reserve: "© 2021 Norregade. All Rights Reserved"

            })
        } else {
            setData({
                ourProducts: "Vores produkter",
                clothing: "Tøj",
                protection: "Beskyttelse",
                rackBags: "Rack & Tasker",
                lights: "Lys",
                bikeGear: "Cykel gear",
                toolkits: "Værktøjssæt",
                aboutNorregade: "Om Norregade",
                ourBrand: "Vores mærke",
                becomeARetailer: "Bliv forhandler",
                ourDistributor: "Vores distributør",
                privacyPolicy: "Fortrolighedspolitik",
                support: "Support",
                returnExchange: "Returnering og ombytning",
                feedback: "Feedback",
                productManuals: "Produktmanualer",
                contactUs: "Kontakt os",
                findARetailer: "Find en forhandler",
                followUs: "Følg os",
                reserve: "© 2021 Norregade. Alle rettigheder forbeholdes"
            })
        }
    }, [lang])
    return (
        <footer className="footer">
            <ul className='main'>
                <li className='title'>
                    <span>{data.ourProducts}</span> <br />
                    <ul className='child'>
                        <li><a>{data.clothing}</a></li>
                        <li><a>{data.protection}</a></li>
                        <li><a>{data.rackBags}</a></li>
                        <li><a>{data.lights}</a></li>
                        <li><a>{data.bikeGear}</a></li>
                        <li><a>{data.toolkits}</a></li>
                    </ul>
                </li>
                <li className='title'>
                    <span>{data.aboutNorregade}</span> <br />
                    <ul className='child'>
                        <li><a>{data.ourBrand}</a></li>
                        <li><a>{data.becomeARetailer}</a></li>
                        <li><a>{data.ourDistributor}</a></li>
                        <li><a>{data.privacyPolicy}</a></li>
                    </ul>
                </li>
                <li className='title'>
                    <span>Support</span> <br />
                    <ul className='child'>
                        <li><a>{data.returnExchange}</a></li>
                        <li><a>Feedback</a></li>
                        <li><a>{data.productManuals}s</a></li>
                        <li><a>{data.contactUs}</a></li>
                        <li><a>FAQS</a></li>
                    </ul>
                </li>
                <li className='title last-element'>
                    <span>{data.findARetailer}</span> <br />
                    <ul className='child'>
                        <li><a>Philippines</a></li>
                        <li><a>Worldwide</a></li>
                    </ul>
                </li>
            </ul>
            <div className='footer-tablet tablet'>
                <div className='row '>
                    <div className='col-sm-6 header'>
                        <span className='title'>{data.ourProducts}</span> <br/>
                        <ul className='child'>
                            <li><a>{data.clothing}</a></li>
                            <li><a>{data.protection}</a></li>
                            <li><a>{data.rackBags}</a></li>
                            <li><a>{data.lights}</a></li>
                            <li><a>{data.bikeGear}</a></li>
                            <li><a>{data.toolkits}</a></li>
                        </ul>
                    </div>
                    <div className='col-sm-6 header'>
                        <span className='title'>{data.aboutNorregade}</span> <br/>
                        <ul className='child'>
                            <li><a>{data.ourBrand}</a></li>
                            <li><a>{data.becomeARetailer}</a></li>
                            <li><a>{data.ourDistributor}</a></li>
                            <li><a>{data.privacyPolicy}</a></li>
                        </ul>
                    </div>
                    <div className='col-sm-6 header'>
                        <span className='title'>Support</span> <br/>
                        <ul className='child'>
                            <li><a>{data.returnExchange}</a></li>
                            <li><a>Feedback</a></li>
                            <li><a>{data.productManuals}</a></li>
                            <li><a>{data.contactUs}</a></li>
                            <li><a>FAQS</a></li>
                        </ul>
                    </div>
                    <div className='col-sm-6 header'>
                        <span className='title'>{data.findARetailer}</span> <br/>
                        <ul className='child'>
                            <li><a>Philippines</a></li>
                            <li><a>Worldwide</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className='' />
            <ul className='social-media desktop'>
                <li className='f-left'>
                    <span>{data.followUs}:</span><br />
                    <ul className='icon-list'>
                        <li><a><img className='icons' src='/assets/icons/facebook.png'></img></a></li>
                        <li><a href='https://www.instagram.com/norregadeph/'><img className='icons' src='/assets/icons/instagram.png'></img></a></li>
                    </ul>
                </li>
                <li className='f-right'>
                    <a className='eng-dan cursor-pointer'>
                        <a onClick={() => setLang("English")}>
                            <span style={lang === "English" ? { color: '#F05A14' } : { color: '#e5e7e7' }}>ENG</span>
                        </a>
                        <span> | </span>
                        <a onClick={() => setLang("Danish")}>
                            <span style={lang === "Danish" ? { color: '#F05A14' } : { color: '#e5e7e7' }}>DAN</span>
                        </a>
                    </a><br />
                    <span>{data.reserve}</span>
                </li>
            </ul>
            <ul className='social-media-mobile mobile'>
                <li className='mb-20'>
                    <a className='eng-dan'>ENG | DAN</a><br />
                </li>
                <li className=''>
                    <span>{data.followUs}:</span><br />
                    <ul className='icon-list'>
                        <li><a><img className='icons' src='/assets/icons/facebook.png'></img></a></li>
                        <li><a href='https://www.instagram.com/norregadeph/'><img className='icons' src='/assets/icons/instagram.png'></img></a></li>
                    </ul>
                </li>
                <li className='mt-2m f12'>
                    <span>{data.reserve}</span>
                </li>
            </ul>
            
        </footer>
    )
}

export default Footer;
