import React, { useEffect } from "react";

function Retailer() {
  useEffect(() => {
    document
      .querySelectorAll(
        "#norregate-img,#norregate-menu,#norregate-earth,#norregate-close,#norregate-img-mobile,#norregate-menu-mobile,#norregate-earth-mobile,#norregate-close-mobile"
      )
      .forEach((el) => {
        el.classList.add("invert");
      });
      document.querySelectorAll(
        "#norregate-flip-menu"
      )
      .forEach((el) => {
          el.classList.add("txt-white");
      });
    document.querySelector("footer").classList.add("hide");
  }, []);
  return (
    <div className="retailer-grid">
      <image className="retailer-grid-bg"/>
      <div className="column-one" id="column-one">
        <div className="box">
          <h4 className="title">FIND A RETAILER</h4>
          <p className="description">
            Get your hand on some Norregade products in a store near you.
          </p>
          <div className="branch">
            <img className="pin" />
            <h5>SPORTSLINE</h5>
            <p>Branch address here</p>
            <p>Branch number here</p>
            <p>sportlines.com.ph</p>
          </div>
          <div className="branch">
            <img className="pin" />
            <h5>OLYMPIC VILLAGE</h5>
            <p>Branch address here</p>
            <p>Branch number here</p>
            <p>sportlines.com.ph</p>
          </div>
          <div className="branch"> 
            <img className="pin" />
            <h5>CHRIS SPORTS</h5>
            <p>Branch address here</p>
            <p>Branch number here</p>
            <p>sportlines.com.ph</p>
          </div>
          <div className="branch">
            <img className="pin" />
            <h5>R.O.X</h5>
            <p>Branch address here</p>
            <p>Branch number here</p>
            <p>sportlines.com.ph</p>
          </div>
          <div className="branch">
            <img className="pin" />
            <h5>PLAY HARD FITNESS</h5>
            <p>Branch address here</p>
            <p>Branch number here</p>
            <p>sportlines.com.ph</p>
          </div>
        </div>
      </div>
      <div className="column-two" id="column-two">
        <h4 className="title">FIND A RETAILER</h4>
        <p className="description">
          Get your hand on some Norregade products in a store near you.
        </p>
        <input className="location" placeholder="Enter Location" />
        <button className="btn btn-primary button">SEARCH</button>
      </div>
    </div>
  );
}

export default Retailer;
