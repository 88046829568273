import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { configureStore } from "./store";
import { Provider } from "react-redux";
import { PermissionProvider, Show, useAccess } from "react-redux-permission";
import { getUserPermissions } from './Utils/Common';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

const MainApp = () => {
  const { hasPermission, definePermission, isLoaded } = useAccess();
 	
 	// console.log(getUserPermissions());
  useEffect(() => {
    definePermission([]);
  }, []);
 
  return (
    <App />
  );
};
 
ReactDOM.render(
  <Provider store={store}>
    <PermissionProvider store={store} reducerKey="permissions">
      <MainApp />
    </PermissionProvider>
  </Provider>,
  document.getElementById("root")
); 

// ReactDOM.render(<MainApp />,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
