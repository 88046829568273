import React, { Fragment, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import UserContext from './UserContext';
import validator from 'validator'

export default function Propelling() {

    const { lang } = useContext(UserContext)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [data, setData] = useState('')
    const [email, setEmail] = useState("")

    useEffect(() => {

        const screenResize = () => {
            const newWidth = window.innerWidth;
            setScreenWidth(newWidth);
        }

        window.addEventListener("resize", screenResize);

        return () => window.removeEventListener("resize", screenResize)

    }, []);

    useEffect(() => {
        if (lang === "English") {
            setData({
                a: "PROPELLING PEOPLE FORWARD TOGETHER. JOIN THE JOURNEY AND RIDE WITH US.",
                b: "BECOME A RETAILER",
                c: "Never miss out on the action",
                d: "Sign up to the NØRREGADE Family so you can get the latest updates on offers and drops",
                e: "Email Address",
                f: "SIGN UP"

            })
        } else {
            setData({
                a: "DRIVE FOLK FREM SAMMEN. KOM MED PÅ REJSEN OG KØR MED OS.",
                b: "BLIV FORHANDLER",
                c: "Gå aldrig glip af handlingen",
                d: "Tilmeld dig NØRREGADE Familien, så du kan få de seneste opdateringer om tilbud og drops",
                e: "Email adresse",
                f: "TILMELDE"
            })
        }
    }, [lang])

    const addMailToList = async () => {
        if (!validator.isEmail(email)) {
            return Swal.fire({
                title: "Error",
                icon: "error",
                text: "Please input a valid email",
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'swal-button'
                }
            })
        }
        const request = await fetch("https://n047dfodhg.execute-api.ap-southeast-1.amazonaws.com/mailinglist", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
        if (request.status === 200) {
            setEmail("")
            return Swal.fire({
                title: "Success",
                icon: "success",
                text: "You have successfully signed up to our mailing list",
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'swal-button'
                }
            })
        }
        if (request.status !== 200) {
            return Swal.fire({
                title: "Error",
                icon: "error",
                text: "Unsuccessfull operation. Please refresh this page and try again.",
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'swal-button'
                }
            })
        }

    }

    return (
        <>
            <div className='oc-rectangle-30'>
                <img alt='Home'
                    src={(screenWidth <= 480) ? '/assets/images/mobile/MB_CTA.png'
                        : (screenWidth <= 1024) ? '/assets/images/tablet/TB_Home_CTA.png'
                            : (screenWidth > 1024) ? '/assets/images/desktop/DT_Home_CTA.png'
                                : null
                    }
                />
                <div>
                    <h1>
                        {data.a}
                    </h1>
                    <button className='btn btn-primary mt-2'>
                        {data.b}
                    </button>
                </div>
            </div>
            <div className='oc-rectangle-31'>
                <div className='oc-div-31'>
                    <h2>{data.c}</h2>
                    <p>{data.d}</p>
                </div>
                <div className='oc-div-32'>
                    <input placeholder={data.e} onChange={e => setEmail(e.target.value)} value={email}></input>
                    <div>
                        <button className="btn btn-black button" onClick={() => addMailToList()}>{data.f}</button>
                    </div>
                </div>
            </div>
        </>
    )
}