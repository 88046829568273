import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { permissionsReducer as permissions } from "react-redux-permission";

// All reducers

// persist config whitelist reducer
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [] // reducer will be persisted
}

// combining all reducers
const rootReducer = combineReducers({
     
});
 
export default persistReducer(persistConfig, rootReducer); 