import React, { useEffect, useContext, useState } from 'react'
import Banner from './Banner'
import UserContext from "../UserContext";

export default function PageNotFound(){
	const {lang} = useContext(UserContext)
	const [data, setData] = useState({})
	useEffect(() => {
		if (lang === "English") {
			setData({
				title: "404 - Not found",
				content: "The page you are looking for cannot be found",
				destination: "/",
				label: "Back to home"
			})
		}else {
			setData({
				title: "404 - Ikke fundet",
				content: "Den side du leder efter kan ikke findes",
				destination: "/",
				label: "Tilbage til hjemmet"
			})
		}
	}, [lang])

	return(
		<>
			<Banner bannerProps={data}/>
		</>
	)
}