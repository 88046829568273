import React, { Fragment, useContext, useEffect, useState } from "react";
import engData from './List.json'
import danData from './Dan.json'
import { Link } from 'react-router-dom'
import UserContext from "../UserContext";


function Products(props) {
    
    const { lang } = useContext(UserContext)
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([])
    const [active_cat, setActiveCat] = useState('All');

    // let active_cat = 'All';
    useEffect(() => {
        if (lang === "English") {
            setProducts(engData.products)
            setCategories(engData.categories)
        } else {
            setProducts(danData.products)
            setCategories(danData.categories)
        }
    }, [lang])


    const clickActiveCat = (value) => {
        setActiveCat(value); 
        let prod = [];
        if (lang === "English") {
            
            if(value == 'All')
            {
                setProducts(engData.products)
            }
            else
            {
                engData.products.map(products => {
                    if(products.category == value){
                        prod.push(products)
                    }
                })
                setProducts(prod)
            }
            
        } else {
            if(value == 'Alle')
            {
                setProducts(danData.products)
            }
            else
            {
                danData.products.map(products => {
                    if(products.category == value){
                        prod.push(products)
                    }
                })
                setProducts(prod)
            }
        }
    }

    return (
        <div className="row mr-0 ml-0">
            <div className="product-main-banner">
                {lang === "English" ?
                    <Fragment>
                        <div className="title-content">
                            <h1>ØUR GEAR</h1>
                            <p>Components, Accessories & Equipment Kits</p>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="title-content">
                            <h1>VORES GEAR</h1>
                            <p>Komponenter, tilbehør og udstyrssæt</p>
                        </div>
                    </Fragment>
                }
            </div>
            <div className="bg-gray container">

                <div className="row ml-0">
                    <div className="col-md-12 text-center">
                        <ul className="category-list">
                        {categories.map((category, index) => {
                            if (category == active_cat) {
                                return (
                                    <Fragment key={index}>
                                        <li className="text-uppercase category-div-h4">
                                            <a href="javascript:void(0)" className="font-black link-active primary-hover" onClick={() => clickActiveCat(category)}>{category}</a>
                                        </li>
                                    </Fragment>
                                )
                            }
                            else {
                                return (
                                    <Fragment key={index}>
                                        <li className="  text-uppercase category-div-h4">
                                            <a href="javascript:void(0)" className="font-black primary-hover" onClick={() =>  clickActiveCat(category)}>{category}</a>
                                        </li>
                                    </Fragment>
                                )
                            }
                        })}
                    </ul>
                    </div>
                </div>
                {products.map((product, index) => {

                    let colors = product.colors;

                    return (
                        <Fragment key={index}>
                            <div className={(product.sku == 'lys' || product.sku == 'pude' ? "" : "border-bottom") + " row product-div " +(product.variation && "variation")}>
                                <div className="col-lg-6 product-div-img"> <center><img className="product-img mt-3m-res" src={product.main_image} /></center></div>
                                <div className="col-md-6 product-div-desc desktop">
                                    <div className="">
                                        <div><h6 className="product-title">{product.name}</h6></div>
                                        <div><h3 className="product-header">{product.header}</h3></div>
                                        <div><h4 className="product-content">{product.content}</h4> <br /><br /></div>
                                        <div className="d-flex align-items-center justify-content-between mw-30m">
                                            <div><Link className="btn btn-primary-reverse" to={`/product/sku/${product.sku}`}>VIEW PRODUCT</Link></div>
                                            <div>
                                                {colors.map((color, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <span className="prod-circle-color" style={{ background: color.color }}></span>
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col-md-12 tablet-mobile">
                                        <div className="row">
                                            <div className="col-sm-6 pl-3em ">
                                                <h6 className="product-title mobile-center">{product.name}</h6>
                                                <h4 className="product-header mobile-center">{product.header}</h4>
                                                <p className="product-content mobile-center">{product.content}</p>
                                            </div>
                                            <div className="col-sm-6 mobile-center">
                                                <a className="btn btn-primary-reverse btn-prod" href={`/product/sku/${product.sku}`}>VIEW PRODUCT</a>
                                            </div>
                                        </div>
                                    </div>
                                <br />


                            </div>
                            {
                                product.sku == 'lys' ?
                                    <div className="row product-div">
                                        <div className="col-md-12 product-banner lys-banner">
                                            <div className="col-lg-6">
                                                <div className="mt-25p ml-10p mobile-center">
                                                    <label className="txt-white">NORREGADE</label>
                                                    {lang === "English" ?
                                                        <Fragment>
                                                            <h1 className="txt-white mobile-center">WHERE DESIGN<br /> & TECHNOLOGY<br />INTERSECT</h1>
                                                            <a className="btn btn-primary">SHOP THE LOOK</a>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <h1 className="txt-white mobile-center">HVOR DESIGN<br /> & TEKNOLOGI<br />KRYDSE</h1>
                                                            <a className="btn btn-primary">SHOP LOOKET</a>
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                product.sku == 'pude' ?
                                    <div className="row product-div mt-2m">
                                        <div className="col-md-12 row product-banner pude-banner">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <div className="mt-25p ml-10p mobile-center">
                                                    {lang === "English" ?
                                                        <Fragment>
                                                            <label className="txt-white">OUR PRIORITIES ARE SIMPLE</label>
                                                            <h1 className="txt-white mobile-center">QUALITY,<br />DURABILITY<br /> & LONGEVITY</h1>
                                                            <a className="btn btn-info">View Accessories</a>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <label className="txt-white">VORES PRIORITETER ER ENKLE</label>
                                                            <h1 className="txt-white mobile-center">KVALITET,<br />HOLDBARHED<br /> & LANG LEVETID</h1>
                                                            <a className="btn btn-info">Se tilbehør</a>
                                                        </Fragment>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                        </Fragment>

                    )
                })}
            </div>
        </div>
    )
}

export default Products; 